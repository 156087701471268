/**
 ##**************************************************************
 ##
 ## Copyright (C) 2018-2020, OneDataShare Team, 
 ## Department of Computer Science and Engineering,
 ## University at Buffalo, Buffalo, NY, 14260.
 ## 
 ## Licensed under the Apache License, Version 2.0 (the "License"); you
 ## may not use this file except in compliance with the License.  You may
 ## obtain a copy of the License at
 ## 
 ##    http://www.apache.org/licenses/LICENSE-2.0
 ## 
 ## Unless required by applicable law or agreed to in writing, software
 ## distributed under the License is distributed on an "AS IS" BASIS,
 ## WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ## See the License for the specific language governing permissions and
 ## limitations under the License.
 ##
 ##**************************************************************
 */


import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";

export default class TripleIconSlice extends Component {

    render(){
        return(
            <Grid className="tripleContent" item container xs={12} md={4} direction="column">
                <img className='iconTrio' src={this.props.img} alt={this.props.imgAltTxt} />
                <h2>{this.props.title}</h2>
            </Grid>
        );
    }
}